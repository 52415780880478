<template>
  <div v-loading="loading">
    <div class="main-title">打卡课程</div>

    <div class="content-list-page page-content">
      <div class="list-head-box">
        <div class="list-head" data-v-69874ad0>
          <p class="list-head-title" data-v-69874ad0>温馨提示</p>
          <ul data-v-69874ad0>
            <li data-v-69874ad0>XXXXXX</li>
          </ul>
        </div>
      </div>

      <div class="margin-sm">
        <div>
          <el-select v-model="status" clearable placeholder="状态" size="small">
            <el-option label="上架" value="1"></el-option>
            <el-option label="下架" value="0"></el-option> </el-select
          >&nbsp;&nbsp;&nbsp;&nbsp;
          <el-input
            v-model="name"
            placeholder="名称"
            style="width: 240px"
          />&nbsp;&nbsp;&nbsp;&nbsp;
          <el-button size="mini" type="primary" @click="getPunchData">
            筛选
          </el-button>
          &nbsp;&nbsp;&nbsp;&nbsp;

          <el-button
            size="mini"
            type="primary"
            @click="$router.push('/checkCourse/add')"
          >
            新增
          </el-button>

          <el-button
            size="mini"
            @click="
              dialogVisible = true;
              ruleForm = {};
            "
          >
            学员课程导出
          </el-button>
        </div>

        <el-table :data="tableData">
          <el-table-column label="id" prop="id" />

          <el-table-column label="名称" prop="name" />

          <el-table-column label="金额" prop="amount" />

          <el-table-column label="有效期" prop="indate" />

          <el-table-column label="是否上架">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.status"
                :active-value="1"
                :inactive-value="0"
                @change="switchStatus(scope.row)"
              />
            </template>
          </el-table-column>

          <el-table-column label="封面">
            <template slot-scope="scope">
              <el-image
                :preview-src-list="[imageUrl + scope.row.pic]"
                :src="imageUrl + scope.row.pic"
                class="table-item-img"
              />
            </template>
          </el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="
                  $router.push({
                    path: '/checkCourse/detail/' + scope.row.id + '/manage',
                  })
                "
                >详情
              </el-button>
              -
              <el-link
                type="primary"
                @click="
                  $router.push({
                    path: '/checkCourse/edit/' + scope.row.id,
                  })
                "
                >编辑
              </el-link>
              -
              <el-Popconfirm
                title="确定要删除吗"
                @confirm="deletePunchMethod(scope.row)"
              >
                <el-button
                  v-if="$store.state.user.info.role === 1"
                  slot="reference"
                  type="text"
                  >删除
                </el-button>
              </el-Popconfirm>
              -
              <el-Popconfirm
                title="确定要复制吗"
                @confirm="copyPunchMethod(scope.row)"
              >
                <el-button
                  v-if="$store.state.user.info.role === 1"
                  slot="reference"
                  type="text"
                  >复制
                </el-button>
              </el-Popconfirm>
              <span v-if="scope.row.status == 1">
                -
                <el-link
                  v-if="scope.row.is_recommend === 0"
                  type="primary"
                  @click="toSetRecommend(scope.row.id)"
                  >推荐</el-link
                >
                <el-link
                  v-else
                  type="danger"
                  @click="toSetRecommend(scope.row.id)"
                  >取消推荐</el-link
                >
              </span>
            </template>
          </el-table-column>
        </el-table>

        <div class="table-batch">
          <span class="fl-r">
            <el-pagination
              :current-page="page"
              :page-size="pageSize"
              :page-sizes="[10, 20]"
              :total="total"
              background
              layout="total, sizes, prev, pager, next, jumper"
              small
              @size-change="setPageSize"
              @current-change="setPage"
            >
            </el-pagination>
          </span>
          <div style="clear: both"></div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      append-to-body
      title="导出筛选"
      width="600px"
    >
      <div style="padding: 10px">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          class="demo-ruleForm"
          label-width="100px"
          size="mini"
        >
          <el-form-item label="ID或昵称：">
            <el-select v-model="region" placeholder="请选择活动区域">
              <el-option :value="1" label="昵称"></el-option>
              <el-option :value="2" label="uid"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="region === 2" label="用户id：">
            <el-input v-model="ruleForm.user_id" style="width: 300px" />
          </el-form-item>
          <el-form-item v-if="region === 1" label="用户昵称：">
            <el-input v-model="ruleForm.user_name" style="width: 300px" />
          </el-form-item>
          <el-form-item label="报单id：">
            <el-input v-model="ruleForm.sales_id" style="width: 300px" />
          </el-form-item>
          <el-form-item label="更新日期：">
            <el-date-picker
              v-model="ruleForm.date_pick"
              end-placeholder="结束日期"
              range-separator="至"
              start-placeholder="开始日期"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-button
              :loading="deriveLoading"
              type="primary"
              @click="derive('ruleForm')"
              >确定
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { config } from "@/js/config";

export default {
  data: () => ({
    tableData: [],
    total: 0,
    loading: false,
    imageUrl: config.imageUrl,
    name: "",
    pageSize: 10,
    page: 1,
    status: "",
    dialogVisible: false,
    ruleForm: {},
    region: 1,
    deriveLoading: false,
  }),

  methods: {
    ...mapActions("punch", [
      "getPunchList",
      "deletePunch",
      "switchDetailStatus",
      "checkCourseCopy",
      "exportAllUser",
    ]),
    ...mapActions("common", ["setRecommend"]),
    async derive() {
      try {
        this.deriveLoading = true;
        const { res_info } = await this.exportAllUser(this.ruleForm);
        if (res_info !== "ok") return;
        this.$message.success("添加到队列中");
        this.dialogVisible = false;
      } finally {
        this.deriveLoading = false;
      }
    },
    async copyPunchMethod({ id }) {
      this.$notify.info({
        title: "提示",
        message: "复制中~",
        duration: 0,
      });
      const { res_info } = await this.checkCourseCopy({ id });
      this.$notify.closeAll();
      if (res_info != "ok") return;
      this.$message.success("复制成功");
      await this.getPunchData();
    },
    async getPunchData() {
      this.loading = true;

      const { data } = await this.getPunchList({
        name: this.name,
        pageSize: this.pageSize,
        page: this.page,
        status: this.status,
      });

      this.loading = false;

      this.tableData = data.list;
      this.total = data.total;
    },

    setPageSize(value) {
      this.pageSize = value;
      this.getPunchData();
    },

    setPage(value) {
      this.page = value;
      this.getPunchData();
    },

    async switchStatus({ status, id }) {
      this.loading = true;

      await this.switchDetailStatus({
        model: "CheckCourseModel",
        id,
        status,
      });

      this.loading = false;

      this.getPunchData();
    },

    async deletePunchMethod({ id }) {
      this.loading = true;
      await this.deletePunch({ model: "CheckCourse", id });

      this.loading = false;
      this.getPunchData();
    },
    // 推荐
    toSetRecommend(id) {
      this.loading = true;
      this.setRecommend({ id: id, model: "CheckCourseModel" })
        .then((res) => {
          this.loading = false;
          if (res.ret == 0) {
            this.$message.success("操作成功");
            this.getPunchData();
          }
        })
        .catch(() => {
          //this.$message.error('操作失败')
        });
    },
  },

  created() {
    this.getPunchData();
  },
};
</script>